<template>
  <page-header-wrapper :title='this.userTypeStr'>
    <a-card title="审核详情" v-if="order.id" :bordered='false'>
      <span slot='extra'>
           <template>
            <a-space style="margin-left: 20px" >
                    <div><a  @click='openDetail("1")'>购买人实时可用积分：<span class="color-red">{{order.memberCashBalance}}</span></a></div>
                    <div v-if="userType=='1'" style="margin-left:40px"><a  @click='openDetail("2")'>归属商户实时可用积分：<span class="color-red">{{order.memberMerchantCashBalance}}</span></a></div>

            </a-space>

          </template>
      </span>


      <a-descriptions :column="4">
        <a-descriptions-item label='订单号'>{{order.id}}</a-descriptions-item>
        <a-descriptions-item label='下单时间'>{{order.createTime}}</a-descriptions-item>
        <a-descriptions-item label='下单方式'>{{order.operatorNickname}} </a-descriptions-item>
        <a-descriptions-item  v-if="!order.backRecord" label='订单留言'><span style="color: #E62734;">{{ order.remark }}</span></a-descriptions-item>
        <a-descriptions-item v-else></a-descriptions-item>

        <a-descriptions-item label='购买人姓名'>
          <span v-if="order.memberShareholder=='1'" class="color-red">
            {{order.nickname}}
          </span>
          <span v-else>
             {{order.nickname}}
          </span>
        </a-descriptions-item>
        <a-descriptions-item label='购买人手机号'>{{order.memberMobile}}</a-descriptions-item>
        <a-descriptions-item label='购买人编码'>{{getUserCode(order.shareholder,order.memberType,order.memberLevel)}}</a-descriptions-item>
        <a-descriptions-item></a-descriptions-item>

        <a-descriptions-item label='推荐人姓名'>
           <span v-if="order.spreaderShareholder=='1'" class="color-red">
            {{order.spreaderMasterName}}
          </span>
          <span v-else>
             {{order.spreaderMasterName}}
          </span>
        </a-descriptions-item>
        <a-descriptions-item label='推荐人手机号'>{{order.spreaderMobile}}</a-descriptions-item>
        <a-descriptions-item label='推荐人编码'>{{getUserCode(order.shareholder,order.spreaderType,order.spreaderLevel)}}</a-descriptions-item>
        <a-descriptions-item label='分公司名称'>{{order.spreaderAreaName}}</a-descriptions-item>
<!--        <a-descriptions-item label='分公司名称'>{{order.memberAreaName}}</a-descriptions-item>-->

        <template v-if="!order.isShowOne">
          <!--          非后台录单显示-->
            <a-descriptions-item label='商户名称'>{{order.merchantName}}</a-descriptions-item>
            <a-descriptions-item label='负责人姓名'>{{order.masterName}}</a-descriptions-item>
            <a-descriptions-item label='所属商户编码'>
              <span v-for="(item, index) in getAuthCodeList(order.authCode)" :key="index"
                    :style="{color: index == 1 ? '#1890ff' : ''}">{{ item }}</span>
            </a-descriptions-item>
            <a-descriptions-item ></a-descriptions-item>
        </template>
          <template v-if="order.isShowOne">
<!--            <a-descriptions-item label='订单金额' v-if="order.merchantMember==order.member">{{order.firstMerchantAmount}}</a-descriptions-item>-->
<!--            <a-descriptions-item label='订单金额' v-else>{{order.amount}}</a-descriptions-item>-->
            <a-descriptions-item label='订单金额'>{{order.amount}}</a-descriptions-item>

            <a-descriptions-item label='购买人应付' v-if="order.merchantMember==order.member">{{order.originalMerchantAmount}}</a-descriptions-item>
            <a-descriptions-item label='购买人应付' v-else>{{order.amount}}</a-descriptions-item>

            <a-descriptions-item v-if="order.merchantMember==order.member" label='积分抵扣'>{{order.merchantDiscount}}</a-descriptions-item>
            <a-descriptions-item v-else label='积分抵扣'>{{order.memberDiscount}}</a-descriptions-item>
            <a-descriptions-item v-if="order.merchantMember && order.merchantMember==order.member" label='转账优惠'>{{order.bankTransDiscount}}</a-descriptions-item>
            <a-descriptions-item v-else></a-descriptions-item>

            <template v-if="order.merchantMember==order.member">
              <!--           商户购买的订单-->
              <a-descriptions-item label='购买人实付'><span class="color-red">{{order.merchantAmount}}</span></a-descriptions-item>
              <a-descriptions-item label='购买人支付方式'>{{order.merchantPaymentMethodName }}</a-descriptions-item>
              <a-descriptions-item label='购买人支付时间' :span="2">{{order.merchantActualPaymentTime}}</a-descriptions-item>
            </template>
            <template v-else>
              <!--           用户购买的订单-->
              <a-descriptions-item label='购买人实付'><span class="color-red">{{order.consumeAmount}}</span></a-descriptions-item>
              <a-descriptions-item label='购买人支付方式'>{{order.paymentMethodName }}</a-descriptions-item>
              <a-descriptions-item label='购买人支付时间' :span="2">{{order.createTime}}</a-descriptions-item>
            </template>
          </template>

<!--          <template v-if="order.isShowTwo">-->
<!--            <a-descriptions-item label='商户原始应付'>{{order.firstMerchantAmount}}</a-descriptions-item>-->
<!--            <a-descriptions-item label='商户应付'>{{order.originalMerchantAmount}}-->
<!--              <a-space style="margin-left: 20px">-->
<!--                <a @click='initialDeliver2(order)'>修改</a>-->
<!--              </a-space>-->
<!--            </a-descriptions-item>-->

<!--            <a-descriptions-item label='积分抵扣'>{{order.merchantDiscount}}</a-descriptions-item>-->
<!--            <a-descriptions-item label='转账优惠'></a-descriptions-item>-->


<!--            <a-descriptions-item label='商户实付'>{{order.merchantAmount}}</a-descriptions-item>-->
<!--            <a-descriptions-item label='商户支付方式'>{{order.merchantPaymentMethodName}}</a-descriptions-item>-->
<!--            <a-descriptions-item label='商户支付时间'>{{order.merchantActualPaymentTime}}</a-descriptions-item>-->
<!--&lt;!&ndash;            merchantPaymentTime&ndash;&gt;-->
<!--            <a-descriptions-item label='所在地区'>{{order.area}}</a-descriptions-item>-->
<!--          </template>-->


<!--        <template v-if="order.isShowThree">-->
            <a-descriptions-item label='总销'>{{order.wealthQuotaMemberName}}</a-descriptions-item>
            <a-descriptions-item label='付款人姓名'>{{order.fuZeRen}}</a-descriptions-item>
            <a-descriptions-item label='转账凭证'>
              <img v-for="(item, index) in order.merchantPaymentProof" :key="index" :src='item' style="margin-right: 20px;" width='100px' height="100px" preview="1">
            </a-descriptions-item>
            <a-descriptions-item label='转账方式'>{{order.bankTransBuyerName?'他人代付':'本人付'}}</a-descriptions-item>
<!--        </template>-->

          <a-descriptions-item :span="4"></a-descriptions-item>

            <a-descriptions-item :span="4">
              <template  v-if="!isView">
              <a-space >

                <a-button type='primary' @click='initialDeliver1(order)'>
                  确认收款
                </a-button>
                <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消'
                              @confirm='handleDelete(order)'>
                  <a href='#'>删除</a>
                </a-popconfirm>
              </a-space>
            </template></a-descriptions-item>

        <a-descriptions-item :span="4"></a-descriptions-item>
      </a-descriptions>


    </a-card>

    <a-modal title='确认收款' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver1' :confirmLoading='deliver1.loading'>
      <a-form-model ref='form1' :model='deliver1.form' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item label='订单号' prop='orderNo'>
          {{deliver1.form.orderNo}}
        </a-form-model-item>
        <a-form-model-item v-if="!backRecord" label='总销' prop='merchantAmount'>
          {{deliver1.form.totalSall}}
        </a-form-model-item>
        <a-form-model-item v-if="!backRecord" label='商户名称' prop='merchantName'>
          <a-input disabled v-model='deliver1.form.merchantName' />
        </a-form-model-item>
        <a-form-model-item label='应收金额' prop='originalMerchantAmount'>
              <a-input v-model='deliver1.form.originalMerchantAmount' @change="changeAmount"/>
        </a-form-model-item>

        <a-form-model-item label='剩余可用积分' prop='merchantDiscount'>
          <template>
            <a-input disabled v-model='deliver1.form.merchantDiscount' />
          </template>
        </a-form-model-item>

        <a-form-model-item label=' ' :colon="false">
          <a-radio-group v-model='deliver1.form.isUseds' @change="handleUseChange">
            <a-radio value='0'>使用</a-radio>
            <a-radio value='1'>不使用</a-radio>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item label='使用可用积分' prop='userCashBalance' v-if="deliver1.form.isUseds==='0' && !deliver1.form.orderDiscount">
          <a-input :disabled="!deliver1.form.canModUsesCashBalance" v-model='deliver1.form.userCashBalance' @change="handleChangeCashBalance" />
        </a-form-model-item>

        <template v-if="deliver1.form.orderDiscount">
          <a-form-model-item label='订单已用折扣' prop='orderDiscount' v-if="deliver1.form.isUseds==='0'">
            <a-input disabled  v-model='deliver1.form.orderDiscount' />
          </a-form-model-item>
        </template>

        <a-form-model-item label='实收金额' prop='merchantAmount'>
          <a-input disabled v-model='deliver1.form.merchantAmount' :class="font_class"/>
        </a-form-model-item>
        <a-form-model-item label=' ' prop='payMethod' :colon="false">
          <!--           <a-radio-group name="radioGroup" v-model="deliver1.form.payMethod">-->
          <!--            <a-radio value="trans">-->
          <!--              转账-->
          <!--            </a-radio>-->
          <!--            <a-radio value="cash">-->
          <!--              现金-->
          <!--            </a-radio>-->
          <!--          </a-radio-group>-->
          <a-checkbox style="float: right;" @change="(e) => deliver1.form.checkStatus = e.target.checked ? 1 : 0">
            待核销
          </a-checkbox>

          <!--          <a-checkbox v-if="this.isDingHuoDan" style="float: right;margin-right: 30px;" @change="handleDingHuoChange">-->
          <!--              订货单-->
          <!--          </a-checkbox>-->

        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='可用积分明细' :destroyOnClose='true' :maskClosable='true' width="70%"
             @cancel='()=>this.deliver2.visible=false' :footer="null"
             :visible='deliver2.visible' :confirmLoading='deliver2.loading'>
      <a-descriptions  :column="5">
      </a-descriptions>
      <a-form-model ref='form2' :model='deliver2.form'  layout='inline'>
        <a-form-model-item prop='name'>
          姓名：{{this.guiShuName}}
        </a-form-model-item>

        <a-form-model-item prop='name'>
          积分统计：<span class="color-red">{{this.ext4 }}</span>
        </a-form-model-item>
        <a-form-model-item prop='type'>
          <a-select v-model="deliver2.form.type" placeholder="变动类型" style="width: 250px" @change="changeSelect">

            <a-select-option :value="undefined">
              变动类型
            </a-select-option>
            <a-select-option :value="15">
              推广
            </a-select-option>
            <a-select-option :value="16">
              推广+活动
            </a-select-option>
            <a-select-option :value="28">
              业绩10%
            </a-select-option>
            <a-select-option :value="26">
              业绩5%
            </a-select-option>
            <a-select-option :value="17">
              业绩2%
            </a-select-option>
            <a-select-option :value="24">
              业绩1.5%
            </a-select-option>
            <a-select-option :value="25">
              业绩1-1%
            </a-select-option>
            <a-select-option :value="27">
              业绩1%
            </a-select-option>
            <a-select-option :value="19">
              消费积分
            </a-select-option>
            <a-select-option :value="21">
              批量兑换
            </a-select-option>
            <a-select-option :value="22">
              批量兑换退回
            </a-select-option>
            <a-select-option :value="7">
              取删退回
            </a-select-option>
            <a-select-option :value="1">
              使用
            </a-select-option>
            <a-select-option :value="5">
              转赠
            </a-select-option>
            <a-select-option :value="6">
              受赠
            </a-select-option>
            <a-select-option :value="2">
              修改
            </a-select-option>
            <a-select-option :value="9">
              门店补贴
            </a-select-option>
            <a-select-option :value="29">
              拓展服务
            </a-select-option>

<!--            <a-select-option :value="1">-->
<!--              使用-->
<!--            </a-select-option>-->
<!--            <a-select-option :value="3">-->
<!--              奖励-->
<!--            </a-select-option>-->
<!--            <a-select-option :value="4">-->
<!--              兑换-->
<!--            </a-select-option>-->


<!--            <a-select-option :value="7">-->
<!--              退回-->
<!--            </a-select-option>-->
<!--            <a-select-option :value="8">-->
<!--              活动奖励-->
<!--            </a-select-option>-->

<!--            <a-select-option :value="10">-->
<!--              门票-->
<!--            </a-select-option>-->
<!--            <a-select-option :value="13">-->
<!--              商户删除-->
<!--            </a-select-option>-->
<!--            <a-select-option :value="14">-->
<!--              获得-->
<!--            </a-select-option>-->
<!--            <a-select-option :value="19">-->
<!--              奖励积分-->
<!--            </a-select-option>-->
<!--            <a-select-option :value="23">-->
<!--              解除合同-->
<!--            </a-select-option>-->


          </a-select>
        </a-form-model-item>

        <a-form-model-item prop='start'>
          <a-range-picker
            v-model="dateArr"
            :format="[formatType, formatType]"
            :show-time="{
            hideDisabledOptions: true
          }"
            :disabled-date="disabledDate"
            @change="dateChange"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type='primary' @click='handleSubmit2'>
            查询
          </a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click='handleReset2'>
            重置
          </a-button>
        </a-form-model-item>
      </a-form-model>
      <s-table id="table1" ref='table1' rowKey='id' size='default' :columns='columns1' :scroll='{ x: 1500}' :data='loadData1'>
        <span slot='orderId' slot-scope='text, record'>
          <template>
              {{showCaseText(record)}}
          </template>
        </span>
      </s-table>
    </a-modal>


  </page-header-wrapper>
</template>

<script>
import {Ellipsis, STable} from '@/components'
import {getMember, getMerchantMemberByMobile,} from '@/api/member'
import {
  deleteMemberOrder,
  getMemberCash,
  getMemberOrder,
  payDirectMemberOrder,
  queryMerchantWaitOrder,
} from '@/api/member-order'
import {getFuZeRen, showOrderType} from '@/utils/util'
import moment from 'moment'

const columns = [
  {
    width: 150,
    title: '订单号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    width: 200,
    title: '下单时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 150,
    title: '应付金额',
    dataIndex: 'originalMerchantAmount',
    scopedSlots: { customRender: 'originalMerchantAmount' }
  },
  {
    width: 150,
    title: '收货人',
    dataIndex: 'addressUserName',
    scopedSlots: { customRender: 'addressUserName' }
  },
  {
    width: 150,
    title: '收货人手机号',
    dataIndex: 'addressUserMobile',
    scopedSlots: { customRender: 'addressUserMobile' }
  },
]

const columns1 = [
  {
    width: 80,
    title: '变动类型',
    dataIndex: 'businessName',
    scopedSlots: { customRender: 'businessName' }
  },
  {
    width: 100,
    title: '变动内容',
    dataIndex: 'quantity',
    scopedSlots: { customRender: 'quantity' }
  },
  {
    width: 80,
    title: '变动后可用积分',
    dataIndex: 'afterBalance',
    scopedSlots: { customRender: 'afterBalance' }
  },
  {
    width: 80,
    title: '发生订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 100,
    title: '变动时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 200,
    title: '备注',
    dataIndex: 'des',
    scopedSlots: { customRender: 'des' },
    sorter: true,
  }
]


const formatType = 'YYYY-MM-DD HH:mm';

const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'OrderEdit',
  data() {
    return {
      // orderEnd:'',//定义订单尾号
      // orderType:'',//订单类型1：用户下单，2：商户下单
      guiShuName:'',//归属名称
      ext4:0,//积分合计
      font_class:"",
      font_class_edit:"",
      arrowDown: require('@/assets/arrow-down.png'),
      arrowUp: require('@/assets/arrow-up.png'),
      formatType,
      columns,
      columns1,
      isDingHuoDan:false,
      order: {},
      profile: {},
      merchantProfile:{},
      merchantGxOrderCount: 0,
      merchantGxOrderAmount: 0,
      tempDiscountUseValue:0,
      tempMerchantAmount:0,
      queryParam: {},
      isView:true,
      backRecord:false,//后台录单
      loadData: parameter => {
        let queryParam = this.queryParam;
        queryParam.member = this.id;
        return queryMerchantWaitOrder(Object.assign(parameter, queryParam))
          .then(datum => {
            return datum
          })
      },
      dateArr: [baseStart, baseEnd],
      queryParam1: {
        // merchantOrderStatus: 2,
        start: baseStart,
        end: baseEnd,
      },
      loading: false,
      deliver1: { visible: false, form: {}, loading: false },
      deliver2: { visible: false,
        form: {
          start: baseStart,
          end: baseEnd,
        }, loading: false
      },
      userType:1,//1用户，2商户
      orderType:1,//1用付订单，2商付订单
      userTypeStr:'',
      mType:1,
      loadData1: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        delete parameter.current;
        delete parameter.size;

        let gsName = '';
        if(this.mType==='1'){
          let _profile = this.profile
          parameter.member = _profile.id;
          gsName= _profile.nickname
        }else{
          // parameter.member = this.order.memberMerchant;
          let _merchantProfile = this.merchantProfile
          parameter.member = _merchantProfile.id;
          gsName = _merchantProfile.masterName
        }
        // debugger
        this.guiShuName = gsName;

        let params = Object.assign(parameter,this.queryParam, this.deliver2.form);

        if(!params.member){
          this.$message.error("缺少用户id，请确认");
          return ;
        }

        return getMemberCash(params).then(datum => {
          this.ext4 = datum.ext4;
          // if(datum.records && datum.records.length>0){
          //   this.guiShuName = datum.records[0].userName
          // }
            return datum
          })
      },

    }
  },
  components: { STable, Ellipsis },
  computed: {
  },
  activated(){
    this.id = this.$route.query.id;
    this.userType = this.$route.query.orderType;

    if(this.userType=='1'){
      this.userTypeStr = "用付订单详情"
    }else  if(this.userType=='2'){
      this.userTypeStr = "商付订单详情"
    }
    this.handleInitial()
  },
  created() {
    this.id = this.$route.query.id;
    this.userType = this.$route.query.orderType;
    if(this.userType=='1'){
      this.userTypeStr = "用付订单详情"
    }else  if(this.userType=='2'){
      this.userTypeStr = "商付订单详情"
    }
    // this.handleInitial()

  },
  methods: {
    getRowClassName(record) {
      if (record.activityId) {
        return 'color-red';
      }
    },
    changeMerchantAmount() {
      let originalMerchantAmount = parseFloat(this.deliver2.form.originalMerchantAmount) || 0;
      let cashBalance = parseFloat(this.deliver2.form.cashBalance) || 0;
      if (cashBalance > 0 && originalMerchantAmount > 0) {
        if (originalMerchantAmount >= cashBalance) {
          this.deliver2.form.merchantDiscount = "-" + cashBalance;
          this.deliver2.form.merchantAmount = parseFloat((originalMerchantAmount - cashBalance).toFixed(2)) ;
        } else {
          this.deliver2.form.merchantDiscount = "-" + originalMerchantAmount
          this.deliver2.form.merchantAmount = 0;
        }
      } else {
        this.deliver2.form.merchantDiscount = "";
        this.deliver2.form.merchantAmount = originalMerchantAmount;
      }
    },

    initialDeliver1(record) {
      if(!this.profile){
        this.$message.error('用户不存在');
        return
      }

      this.backRecord = record.operatorNickname=='后台录单';//是否为后台录单

      let tempAmount = 0;//应收金额
      let cashBalance = parseFloat(this.profile.cashBalance);
      let tempDiscount = 0;
      let merchantAmount = 0;
      let canModUsesCashBalance = false;//是否允许修改用户积分
      let isUseds = 1;//默认选择不允许使用
      let userCashBalance = 0;//显示实际使用积分
      let orderType = this.orderType;

      if(this.backRecord){
        // 2、后台给用户和商户录单，录单后需确认收款。此场景可正常修改。
        if(orderType=='1'){//用付订单
          tempAmount = parseFloat(record.amount) || 0;//用户应收金额
          tempDiscount = parseFloat(record.merchantDiscount);//用户折扣
        }else if(orderType=='2'){//商付订单
          tempAmount = parseFloat(record.originalMerchantAmount) || 0;//应收金额
          tempDiscount = parseFloat(record.memberDiscount);//商户户折扣
        }
        isUseds = '1';
        canModUsesCashBalance = true;
      }else{
        //1、商户小程序支付给公司时选择转账或扫码支付，提交到后台确认收款。此场景不能修改使用积分。
        //首先判断订单是否有折扣，有折扣就直接使用订单折扣，没有就开放给用户使用折扣

      }

      if(tempDiscount>0){
        //订单有折扣
        if (tempAmount > tempDiscount) {
          merchantAmount = tempAmount - tempDiscount;
        } else {
          merchantAmount = 0;//等于直接抵扣
        }
        userCashBalance = tempDiscount;
        isUseds = '0';
        canModUsesCashBalance = false;
      }else{
        //订单没有折扣，开放给用户使用帐户积分，可以选择，但不能修改
        isUseds = '1';
        canModUsesCashBalance = true;
        merchantAmount = tempAmount;
      }

      let merchantPaymentMethod = record.merchantPaymentMethod;

      this.deliver1.form = {
        orderNo:record.id,
        id: record.id,
        totalSall:record.wealthQuotaMemberName,//总销名称
        // payMethod: record.merchantPaymentMethod||record.paymentMethod,//||"bank_trans"
        merchantName: record.merchantName,
        merchantDiscount: cashBalance,//现金折扣
        originalMerchantAmount: tempAmount,
        tempOriginalMerchantAmount:tempAmount,
        merchantAmount: merchantAmount,//merchantAmount
        userCashBalance:0,//使用现金折扣初始化
        orderDiscount:userCashBalance,//订单原本折扣
        tempUserCashBalance:cashBalance,//帐户余额临时存放
        isUseds:isUseds,
        tempDiscount:tempDiscount,
        canModUsesCashBalance : canModUsesCashBalance,//是否允许使用
      }
      this.tempDiscountUseValue = tempDiscount;
      this.deliver1.visible = true

    },
    handleDeliver1() {
      this.$refs.form1.validate((result) => {
        if (result) {
          if(!this.deliver1.form.orderDiscount &&  this.deliver1.form.userCashBalance>this.deliver1.form.merchantDiscount){
            this.$message.error("不能大于剩余可用积分")
            return;
          }
          if(this.deliver1.form.isUseds=='0' && !this.deliver1.form.orderDiscount  && !this.deliver1.form.userCashBalance){
            this.$message.error("使用积分不能为空")
            return;
          }

          let isUsed = this.deliver1.form.isUseds;

          this.deliver1.loading = true;
          let params = Object.assign({
            id: this.deliver1.form.id, payMethod: this.deliver1.form.payMethod,
            checkStatus: this.deliver1.form.checkStatus || 0,
            useCash:isUsed,
            originalMerchantAmount:this.deliver1.form.originalMerchantAmount,
          })

          debugger;

          if( isUsed =='0' && !this.deliver1.form.orderDiscount){
            //只有允许修改的时候才能传
            params.cashValue = this.deliver1.form.userCashBalance;
          }
          payDirectMemberOrder(params).then(_result => {
            this.$message.success('操作成功')
            this.handleInitial(this.id);
            this.deliver1.visible = false
            this.deliver1.loading = false;
            this.order = {}
            this.$previewRefresh()
          }).catch(() => {
            this.deliver1.loading = false;
          })
        }
      })
    },
    handleInitial() {

      this.handleChangeOrder();
    },
    handleChangeOrder() {
      getMemberOrder({ id:this.id}).then(result => {
        if (result.merchantPaymentProof) {
          result.merchantPaymentProof = result.merchantPaymentProof.split(",");
        } else {
          result.merchantPaymentProof = [];
        }
        result.fuZeRen = getFuZeRen(result);//获取负责人
        result = showOrderType(result);//公共方法获取显示区域

        let status = result.status;
        let merchantOrderStatus = result.merchantOrderStatus;
        if((status=='paying') ||( merchantOrderStatus=='1') ){
          this.isView = false;
        }else{
          this.isView = true;
        }
        this.order = result
        //  获取用户详情信息，显示数据
        let mid = result.member;
        getMember({ id:mid}).then(result => {
          this.profile = Object.assign({}, result);
          if(result.type=='normal'&&(result.level!='center'||result.level!='president')){
            this.userType='1';
            getMerchantMemberByMobile(Object.assign({mobile: this.profile.mobile})).then(result => {
              this.merchantProfile = Object.assign({}, result);

            }).catch(error=>{
              this.merchantProfile = undefined;
            })
          }else{
            this.userType='2';
          }

        }).catch(error => {
          this.profile =undefined;
        })

        this.$previewRefresh()
      })
    },
    handleDelete(record) {
      deleteMemberOrder({ id: record.id }).then(result => {
        this.$previewRefresh();
        this.$message.success('操作成功')
        this.$router.go(-1);

      })
    },
    disabledDate(current) {
      return current && current > moment().endOf('day');

    },
    dateChange() {
      // this.queryParam1.start =  moment(this.dateArr[0]).format(this.formatType);
      // this.queryParam1.end =  moment(this.dateArr[1]).format(this.formatType);
      this.deliver2.form.start =  this.dateArr[0] ? moment(this.dateArr[0]).format(this.formatType) : "";
      this.deliver2.form.end =  this.dateArr[1] ? moment(this.dateArr[1]).format(this.formatType) : "";
    },
    handleDetail() {
      this.$router.push({ path: '/order/detail', query: { id: this.id } })
    },
    handleUseChange(e){
      let _form = this.deliver1.form;
      if(_form.isUseds=='1'){
        //勾选了不使用
        // this.tempDiscountUseValue = this.deliver1.form.merchantDiscount;
        this.deliver1.form.userCashBalance = 0;
      }else{
        //勾选了使用
        if(this.deliver1.form.tempDiscount>0){
          this.deliver1.form.userCashBalance = this.deliver1.form.tempDiscount;
        }else{
          this.deliver1.form.userCashBalance = this.deliver1.form.tempUserCashBalance;
        }
      }
      this.deliver1.form.merchantAmount = this.initValue();
    },
    changeAmount(e){
      const regex = /^[0-9]\d*$/;
      const value = event.target.value;
      if (!regex.test(value)) {
        this.deliver1.form.originalMerchantAmount = this.deliver1.form.tempOriginalMerchantAmount;
        this.deliver1.form.merchantAmount = this.initValue() ;
        this.deliver1.form.userCashBalance = this.deliver1.form.tempOriginalMerchantAmount;
        this.$message.info('只能输入正整数！');
        return;
      }
      // debugger;
      let _form = this.deliver1.form;
      if(_form.isUseds=='1'){
        //勾选了不使用
        // this.tempDiscountUseValue = this.deliver1.form.merchantDiscount;
        this.deliver1.form.userCashBalance = 0;
      }else{
        //勾选了使用
        if(this.deliver1.form.tempDiscount>0){
          this.deliver1.form.userCashBalance = this.deliver1.form.tempDiscount;
        }else{
          this.deliver1.form.userCashBalance = this.deliver1.form.tempUserCashBalance;
        }
      }
      let totalAmount = this.initValue();
      this.deliver1.form.merchantAmount = totalAmount;
    },
    getAuthCodeList(authCode) {
      if (authCode) {
        let index = authCode.indexOf("-");
        if (index < 0) {
          index = authCode.length - 1;
        } else {
          index--;
        }
        let ownAuthCode = authCode.slice(index - 3, index + 1);
        if (ownAuthCode) {
          const authCodeList = authCode.split(ownAuthCode);
          authCodeList.splice(1, 0, ownAuthCode);
          return authCodeList;
        }
      }
      return [];
    },
    getUserCode(shareholder,type,level){
      // debugger;
      if(shareholder=='1'){
        //股东判断
        return '股东'
      }
      if(type=='store'){
        return '门店'
      }else if(type=='center') {
        // return '服务中心';
        return 'SF';
      }else if(type=='president') {
        // return '运营总裁';
        return 'SY'
      }else if(type=='normal'){
        if(level=='center'){
          // return '备选服务';
          return 'BF';
        } else if(level=='president'){
          // return '备选运营';
          return 'BY';
        }
        // return '用户';
        return 'Y';
      }else{
        return '';
      }
    },
    getStatusStr(record){
      let memberStatus = record.status;
      let merchantOrderStatus = record.merchantOrderStatus;
      // debugger;
      if(memberStatus == 'paying'||merchantOrderStatus == '1'){
        return true;
      }else{
        return false;
      }
    },
    //打开个人积分明细
    openDetail(mType){
      this.deliver2.loading = false;
      this.deliver2.visible = true;
      this.mType = mType;
      delete  this.deliver2.form.type;//每次打开就重置类型。
    },
    showCaseText(record){
      // debugger;
      let text = "";
      if(record.businessName==='修改'||record.businessName==='受让'||record.businessName==='转让'){
        // debugger
        text = record.opUsername;
      }else{
        text = record.orderId;
      }
      // debugger;
      // text = text + record;
      return text;
    },
    changeSelect(){
      this.handleSubmit2()
    },
    handleSubmit2() {
      this.handleInitial();
      this.$refs.table1.refresh(true)
    },
    handleReset2() {
      // debugger;
      this.dateArr = [baseStart, baseEnd];
      this.deliver1.form.start = baseStart;
      this.deliver1.form.end = baseEnd;
      this.$refs.form1.resetFields()
      this.$refs.table1.refresh(true)
    },
    //更改剩余积分时候触发
    handleChangeCashBalance(e){
      const regex = /^[0-9]\d*$/;
      const value = event.target.value;
      if (!regex.test(value)) {
        this.deliver1.form.merchantAmount = this.initValue() - this.deliver1.form.userCashBalance;
        this.deliver1.form.userCashBalance = "";
        this.$message.info('只能输入正整数！');
        return;
      }
      // debugger;
      if( this.deliver1.form.merchantDiscount < this.deliver1.form.userCashBalance){
        this.$message.info('积分不能大于可用积分');
        this.deliver1.form.userCashBalance = this.deliver1.form.tempUserCashBalance;

        let totalAmount = this.initValue();
        this.deliver1.form.merchantAmount = totalAmount;//更新应收金额
        return;
      }
      if(this.deliver1.form.originalMerchantAmount <= this.deliver1.form.userCashBalance){
        //如果可用积分大于实付价格，那么将使用可用积分设置为实收金额
        this.deliver1.form.userCashBalance = this.deliver1.form.originalMerchantAmount;
      }
      //计算实付金额
      this.deliver1.form.merchantAmount = this.deliver1.form.originalMerchantAmount - this.deliver1.form.userCashBalance;
    },
    //超过重制值
    initValue(){
      let totalAmount = 0
      this.deliver1.form.userCashBalance = this.deliver1.form.userCashBalance? this.deliver1.form.userCashBalance:0;
      if(this.deliver1.form.originalMerchantAmount > this.deliver1.form.userCashBalance){
        totalAmount = this.deliver1.form.originalMerchantAmount -  this.deliver1.form.userCashBalance;
        // this.deliver1.form.userCashBalance = totalAmount;
      }else{
        totalAmount = 0;
        this.deliver1.form.userCashBalance = this.deliver1.form.originalMerchantAmount;
      }
      return totalAmount;
    }
  }
}
</script>
<style scoped>
.color-red a{
  color: #E62734 !important;
}

.font-red{
  color: #E62734 !important;
}

.font-black{
  color: #BBBBBB !important;
}
</style>
